import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { useEffect, useRef } from "react";

import { Button } from "@aviary/components/Button";
import type { ButtonProps } from "@aviary/components/Button/Button";

import { useDropdownContext } from "../../../DropdownContext";
import { DropdownChevron } from "../../DropdownChevron";
import { useDropdownButtonProps } from "../useDropdownButtonProps";

import * as styles from "./DeprecatedDropdownButton.styles";

interface Props extends ButtonProps {
  /**
   * if true, button will have chevron icon, or custom icon
   *
   * @default true
   */
  hasIcon?: boolean;
  /**
   * optional custom icon that will be used in place of chevron
   */
  customIcon?: IconDefinition;
  /**
   * Centers the text content
   *
   * @default false
   */
  isCentered?: boolean;
}

const DeprecatedDropdownButton = ({
  children,
  customIcon,
  hasIcon = true,
  isCentered = false,
  ...rest
}: Props) => {
  const dropdownProps = useDropdownButtonProps();
  const { prefixText, isDropdownOpen, triggerElement, setTriggerElement } = useDropdownContext();

  const triggerRef = useRef(null);

  const initializeElement = () => {
    if (isDropdownOpen && !triggerElement) {
      setTriggerElement(triggerRef.current);
    }
  };

  useEffect(() => {
    initializeElement();
  }, []);

  const renderIcon = () => {
    if (hasIcon) {
      return <DropdownChevron customIcon={customIcon} />;
    }
  };

  const renderPrefix = () => {
    return (
      prefixText && (
        <span css={styles.prefix} data-testid="prefix-text">
          {prefixText}:
        </span>
      )
    );
  };

  const buttonStyles = [styles.dropdownButton, isCentered && styles.isCentered.button];

  const contentWrapperStyles = [
    styles.dropdownButtonContentWrapper,
    isCentered && styles.isCentered.contentWrapper,
  ];

  return (
    <Button {...dropdownProps} innerRef={triggerRef} css={buttonStyles} isColor="system" {...rest}>
      <div css={contentWrapperStyles}>
        {renderPrefix()}
        <span css={styles.text}>{children}</span>
      </div>
      {renderIcon()}
    </Button>
  );
};

export { DeprecatedDropdownButton };
